import * as THREE from 'three';
import * as TWEEN from '../libs/tween.esm.js';
import { Utils } from '../core/utils';
import { dispose3 } from './main';

var three;
export class ROUTE {
  cameraControlDiv: HTMLElement;
  shuttleControlDiv: HTMLElement;
  stops: STOP[];
  atStop: number;
  last: number;
  tempLine: any;
  wayMarkerMesh: any;
  currentStop: STOP;
  selectedStop: STOP;
  currentTweens: TWEEN.Tween[];
  playButton: HTMLElement;  
  stopButton: HTMLElement;  
  rwndButton: HTMLElement;  
  ffwdButton: HTMLElement;

  constructor(tthree) {
    this.stops = [];
    this.atStop = 0;
    this.currentTweens = [];
    three =  tthree;

    this.playButton = document.getElementById('playButton');
    this.stopButton = document.getElementById('stopButton');
    this.rwndButton = document.getElementById('rwndButton');
    this.ffwdButton = document.getElementById('ffwdButton');
    this.playButton.addEventListener('click', function() {
      three.autoplayRoute = true;
      three.route.autoPlayNext();
    }.bind(this));
    this.stopButton.addEventListener('click', function() {
      TWEEN.removeAll();
      three.autoplayRoute = false;
    }.bind(this));
    this.ffwdButton.addEventListener('click', function() {
      TWEEN.removeAll();
      if(this.stops[this.atStop].next) {
        this.atStop = this.atStop + 1;
      }
      three.tourCamera.position.copy(this.stops[this.atStop].position);
      three.forceARender = true;
      if(three.autoPlayRoute) {
        three.route.autoPlayNext();
      }
    }.bind(this));
    this.rwndButton.addEventListener('click', function() {
      TWEEN.removeAll();
      if(this.stops[this.atStop].last) {
        this.atStop = this.atStop - 1;
      }
      three.tourCamera.position.copy(this.stops[this.atStop].position);
      three.forceARender = true;
      if(three.autoPlayRoute) {
        three.route.autoPlayNext();
      }
    }.bind(this));
  }
  switchToTourView() {

    three.activeCamera = three.tourCamera;
    three.controls.cameraMovedCallbacks.fire();
      three.controls.enabled = false;
      this.currentStop = this.stops.length ? this.stops[0] : null;
      if(three.route && three.route.stops.length) {
        three.tourCamera.position.copy(this.stops[0].pov);
        three.tourCamera.quaternion.copy(this.stops[0].quaternion)
      }
      else {
        three.tourCamera.position.set(0, 0, 0)
      }
      three.forceARender = true;

      this.autoPlayNext();
      three.autoplayRoute = true;


  }
  switchFromTourView() {
      three.activeCamera = three.orbitCamera;
      three.controls.enabled = true;
      three.controls.cameraMovedCallbacks.fire();
      three.forceARender = true;


  }
  loadFromSaveData(saveData) {
      for(let stop of saveData) {
          this.addStop(new THREE.Vector3(stop.x, stop.y, stop.z));
      }

  }
  addStop(pos) {
      let tPos = pos.clone();
      tPos.x = tPos.x + 0.001;
/*        this.tempLine = new THREE.Line(
          new THREE.BufferGeometry().setFromPoints(
              [pos, tPos]),
              new THREE.LineDashedMaterial({color: 0xff0000}));
              */
      var rot = new THREE.Quaternion();
      let uVecA = new THREE.Vector3();
      new THREE.PerspectiveCamera().getWorldDirection(uVecA);
      let last = this.stops.length ? this.stops[this.stops.length - 1] : null;
      if(last) {

          let uVecB = new THREE.Vector3().subVectors(pos, last.position);
          rot.setFromUnitVectors(uVecA, uVecB);
      }
      let tStop = new STOP(pos,
          this.stops.length,
          last, this);
      if(last) {
          last.quaternion.copy(rot);
          last.next = tStop;
          last.line = new THREE.Line(
              new THREE.BufferGeometry().setFromPoints(
                  [last.position, 

                      pos]
              ),
              new THREE.LineDashedMaterial({color: 0xff0000, dashSize: 15, gapSize: 15})
          );
          last.line.computeLineDistances();
          
      }
      if(last) {
        last.updateFacing();
      }

      this.stops.push(tStop);
      return tStop;
  
  }
  removeStop(stop) {
    Utils.removeValue(this.stops, stop);
    dispose3(stop);
  }
  hide() {
      for(let stop of this.stops) {
          if(stop.parent) {
              stop.parent.remove(stop);
              if(stop.line && stop.line.parent)
                  stop.line.parent.remove(stop.line);
          }
      }
      three.forceARender = true;
      for(let tween of this.currentTweens)
          tween.stop();
  }
  show() {
      for(let stop of this.stops) {
          if(!stop.parent) {
          three.getScene().scene.add(stop);
          }
          if(stop.line && !stop.line.parent) {
              three.getScene().scene.add(stop.line);
              
          }
      }
      three.forceARender = true;

  }
  updateTweens() {
      TWEEN.update();
      three.forceARender = true;
  }
  autoPlayNext() {
      if(this.stops.length <= 1)
          return;
      
      let sN = this.currentStop.next;
      if(!sN)
          return;
      this.currentTweens = [];
      let pTween = new TWEEN.Tween(three.tourCamera.position)
      .to({x: sN.pov.x, y: sN.pov.y, z: sN.pov.z}, sN.travelTime || 3000)
      .onComplete(function() {
          three.route.currentStop = sN;
          if(three.autoplayRoute) {
              setTimeout(function() {
                  three.route.autoPlayNext();
              }, sN.pause)
          }
      })
      .start();

      var rotProg = { prog: 0 };
      let startingQuat = new THREE.Quaternion().copy(three.activeCamera.quaternion);
      three.activeCamera.lookAt(sN.pov.x, sN.pov.y, sN.pov.z);
      let endingQuat = new THREE.Quaternion().copy(three.activeCamera.quaternion);
      three.activeCamera.quaternion.copy(startingQuat);
      let rTween = new TWEEN.Tween( rotProg )
                  .to( { prog : 1 }, 700 )
                
                  .onUpdate( function() {
                      let facingQuat = new THREE.Quaternion().slerpQuaternions(startingQuat, endingQuat, rotProg.prog );
                      three.tourCamera.quaternion.slerpQuaternions( startingQuat, endingQuat, rotProg.prog );
                  } )
                  .onComplete( function() {
                      three.tourCamera.quaternion.copy( endingQuat ); // to be exact
                  } )
                  .start();
      
          
      this.currentTweens = [pTween, rTween];

  }
}


export class STOP extends THREE.Object3D {
  number: number;
  next: STOP;
  last: STOP;
  position: THREE.Vector3;
  line: any;
  mesh: any;
  pause: number;
  travelTime: number;
  hover: boolean;
  highlighted: boolean;
  selected: boolean;
  pov: THREE.Vector3;
  rot: THREE.Quaternion;
  originalColor: any;
  cone: THREE.Mesh;
  route: ROUTE;
  isDatapointItem: boolean = true
  dragOffset: THREE.Vector3;

  constructor(pos, number, last, route) {
      super();
      this.route = route;
      this.number = number;
      this.last = last;
      this.next = null;
      
      this.pause = 0;
      this.line = null;

      this.mesh = this.addMesh();
      this.add(this.mesh);
      this.hover = false;

      this.position.copy(pos);
      this.pov = new THREE.Vector3().copy(pos);

      // 182 cm is approx 5.5'
      // 123 cm is approx 4'
      this.pov.y = 102;
      this.highlighted = false;
      this.selected = false;
      this.dragOffset = new THREE.Vector3();
  }

  addMesh() {
      let tMesh = new THREE.Mesh(new THREE.SphereGeometry(5, 5, 5), new THREE.MeshPhongMaterial({color: "#550000"}));
      let aMesh = new THREE.ArrowHelper(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 0, 0), 30, "#ffff00",30, 30);
      tMesh.add(aMesh);
      aMesh.cone.material = new THREE.MeshPhongMaterial({color: "#ffff00"});
      this.cone = aMesh.cone;
      tMesh.material.color.r = 0.4;
      tMesh.material.emissive.r = 0.4;
      this.originalColor = new THREE.Color().copy(tMesh.material.emissive);

      return tMesh;

      /*
      if(three.controller.wayMarker) {
          for(let mesh of three.controller.wayMarker.children) {
              this.mesh.add(mesh.clone());
          }
          this.mesh.children[0].material.emissive.r = 2;
          this.mesh.children[1].material.emissive.r = 1;
          this.mesh.children[1].material.emissive.g = 1;
          this.mesh.children[1].material.emissive.b = 1;
      }
          */

  }  
      /** on is a bool */
  updateHighlight() {
      var on = this.hover || this.selected;
      this.highlighted = on;
      if(on) {
        three.highlit = this;
      }
      else {
        if(three.highlit === this) {
          three.highlit = null;
        }
      }
      let em = this.cone.material as any;
      // var hex = on ? this.emissiveColor : 0x000000;
            if(on) {
              em.emissive.setHex(0xff5500);
            }
            else {
              em.emissive.setHex(this.originalColor);
            }
            three.forceARender = true;

      /* old code july '24 - can remove a few weeks later

      if (this.children.length) {
        for (let c = 0; c < this.children.length; c++) {
          let child = this.children[c] as any;

          if (child.type === "Mesh") {
            if (isMaterialArray(child.material)) {
              let m = 0;
              for (let mat of child.material) {
                mat.color.set(
                  this.matOrigColors[c][m].r,
                  this.matOrigColors[c][m].g,
                  this.matOrigColors[c][m].b);
                m++;
              }
            }
            else {
              child.material.color.set(
                this.matOrigColors[c][0].r,
                this.matOrigColors[c][0].g,
                this.matOrigColors[c][0].b);
            }
          }
        }
      }
      else {
        if (isMaterialArray(this.materials)) {
          for (let i = 0; i < this.materials.length; i++) {
            let mat = this.materials[i];

            if (this.highlighted) {
              mat.color.set(0x00FFFF)
            }
            else {
              mat.color.set(
                this.matOrigColors[i].r,
                this.matOrigColors[i].g,
                this.matOrigColors[i].b);
            }

            mat.needsUpdate = true;
          }
        }
      }
      */
    }
  removeMe() {
    if(this.next) {
      // wasn't the last stop, so remove this stop's line.
      if(this.line) {
        this.line.parent.remove(this.line);
        dispose3(this.line);
      }
      if(this.last) { // connect last stop's line to next stop
        this.line = null;
        this.last.next = this.next;
        this.next.last = this.last;
        this.last.line.geometry.attributes.position.setXYZ(1, this.next.position.x, this.next.position.y, this.next.position.z);
        this.last.line.geometry.attributes.position.needsUpdate = true;
        this.last.line.computeLineDistances();
        this.last.line.geometry.needsUpdate = true;
        this.last.updateFacing();
      }
      else {
        this.next.last = null;
      }
    }
    else { // no next stop, this was the last
      if(this.last && this.last.line) { // so delete any prev. stop's line
        this.last.line.parent.remove(this.last.line);
        dispose3(this.last.line);
        this.last.line = null;
        this.last.next = null;
      }
    }

    this.route.removeStop(this);
    three.forceARender = true;
  }
  updateFacing() {
    if(this.next) {
      this.lookAt(this.next.position);
    }
    else if(this.last) {
      this.lookAt(this.last.position);
      this.rotation.y -= 3.147
      
    }
  }

  startDrag() {
    three.controller.changeValidTargets('m', 1)
    let inter = three.controller.getIntersections(
      three.controller.currentMouse,
      three.controller.validTargets,
      false, false, true);
    if(!inter.length)
      return;
    let point = inter[0].point;
                point.y = this.position.y;
    if(this.last) {
      this.last.line.geometry.attributes.position.setXYZ(1, point.x, point.y, point.z);
      this.last.line.geometry.needsUpdate = true;

    }
    if(this.line) {
      this.line.geometry.attributes.position.setXYZ(0, point.x, point.y, point.z);
      this.line.geometry.needsUpdate = true;
    }
    three.controller.setDrawing(true);
    three.controls.enabled = false;
  }
  drag() {
    let inter = three.controller.getIntersections(
      three.controller.currentMouse,
      three.controller.validTargets,
      false, false, true);
      if(inter && inter.length) {
                let point = inter[0].point;
        point.y = this.position.y;
    this.position.set(point.x, this.position.y, point.z);
        if(this.last) {
          let attr = this.last.line.geometry.getAttribute('position')
          attr.setXYZ(1, inter[0].point.x, inter[0].point.y, inter[0].point.z);
          attr.needsUpdate = true;
          this.last.line.geometry.needsUpdate = true;
          this.last.line.computeLineDistances();
        }
        if(this.line) {
          let attr = this.line.geometry.getAttribute('position')
          attr.setXYZ(0, inter[0].point.x, inter[0].point.y, inter[0].point.z);
          attr.needsUpdate = true;
          this.line.geometry.needsUpdate = true;
          this.line.computeLineDistances();

          
        }
        if(this.next) {
          this.updateFacing();
        }
    }
  
  
  }
  stopDrag() {
    three.controller.setDrawing(false);
    three.controls.enabled = true;
    this.setUnselected();
  }

      /** */
      mouseOver() {
        if(three.currentTool === three.controller.tools.REMOVESTOP) {
          three.deleteIconElement.style.display = "block";
//          three.syncToPointer(three.deleteIconElement);
          three.doSyncHTMLToObj(this, three.deleteIconElement);
        }
        else {
          this.hover = true;
          this.updateHighlight();
        }
      };
  
      /** */
     mouseOff() {
      if(three.currentTool === three.controller.tools.REMOVESTOP) {
        three.deleteIconElement.style.display = "none";

      }
      else {

        this.hover = false;
        this.updateHighlight();
      }
      };
  
      /** */
      setSelected() {
        if(this === this.route.selectedStop)
          return;
        else if(this.route.selectedStop)
          this.route.selectedStop.setUnselected();
        window.three.setSelected(this);
        this.route.selectedStop = this;

        this.selected = true;
        this.updateHighlight();
        return this;
      };
  
      /** */
      setUnselected() {
        window.three.setSelected(null);
        this.selected = false;
        this.updateHighlight();
      };
  
      /** intersection has attributes point (vec3) and object (THREE.Mesh) */
    clickPressed(intersection) {
        this.dragOffset.copy(intersection.point).sub(this.position);
      };
  
      /** */
 clickDragged(intersection) {
        if (intersection) {
          this.moveToPosition(
            intersection.point.sub(this.dragOffset),
            intersection);
        }
      };
      moveToPosition(vec3, intersection) {
        this.position.copy(vec3);
      }
      /** */
      public rotate(intersection) {
        if (intersection) {
          var angle = Utils.angle(
            0,
            1,
            intersection.point.x - this.position.x,
            intersection.point.z - this.position.z);
  
          var snapTolerance = Math.PI / 16.0;
  
          // snap to intervals near Math.PI/2
          for (var i = -4; i <= 4; i++) {
            if (Math.abs(angle - (i * (Math.PI / 2))) < snapTolerance) {
              angle = i * (Math.PI / 2);
              break;
            }
          }
  
          this.rotation.y = angle;
        }
      }
  
      /** */
 
      public customIntersectionPlanes() {
        return [];
      }
      public clickReleased() {
     /*   if (this.error) {
          this.hideError();
        } */
      };
  
  
  
}

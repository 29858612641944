/// <reference path="../../lib/jQuery.d.ts" />
import Stats from 'three/examples/jsm/libs/stats.module.js';
declare global {
	interface Window {
		sim: any;
    three: any;
	}
}
import { Controller } from "./controller";
//import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import { Controls as OrbitControls } from "./controls";
import { Floorplan } from "./floorPlan";
import { HUD } from "./hud";
import { Lights } from "./lights";
import { Skybox } from './skybox';

import * as THREE from 'three';
import { ROUTE } from './route';
import { Model } from '../model/model';
import { Scene } from '../model/scene';
import * as TWEEN from '../libs/tween.esm.js';
import { HalfEdge } from '../model/half_edge';
var stats;
var IMPERIAL = 0;
var METRIC = 1;

  export var Main = function (textureDir, element, canvasElement, opts) {
    var scope = this;
    stats = new Stats();
    this.units = IMPERIAL;
    document.body.appendChild(stats.dom);
    window.three = this;
    // we're in a 'viewerMode' other than normal
    // when bp3d items aren't valid targets 
    this.viewerModes = {
      NORMAL: 0,
      ROUTEEDIT: 1,
      ROUTEVIEW: 2,
      WALLEDIT: 3,
    }
    this.deleteIconElement = document.getElementById("removeWarningImage");
    var options = {
      resize: true,
      pushHref: false,
      spin: true,
      spinSpeed: .00002,
      clickPan: true,
      canMoveFixedItems: false
    }
    this.tempLine = new THREE.Line(new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, 1, 1)]), new THREE.LineBasicMaterial());
    this.tempLine.labelFocus = this.tempLine;  // it's own mesh is the label focus
    let sidebar = document.getElementsByClassName('sidebar')[0] as HTMLElement;
    sidebar.addEventListener('mouseenter', function() {
      if(window.three.controller.mouseDown) {
        this.style.transform = "translateX(-100%)";
        let scoochInterval = setInterval(function() {
          if(!window.three.controller.mouseDown) {
            this.style.transform = "";
            clearInterval(scoochInterval);
          }
        }.bind(this), 500)
      }
    })

    let unitSelectElem = document.getElementById('unitSelect');
    unitSelectElem.addEventListener('change', function(e) {
      let targ = e.target as any;

      scope.units = parseInt(targ.value);
      window.three.updateVisibleUnits();
    })

    // override with manually set options
    for (var opt in options) {
      if (options.hasOwnProperty(opt) && opts.hasOwnProperty(opt)) {
        options[opt] = opts[opt]
      }
    }
    this.loader;

    this.element = $(element);
    scope.domElement;

    var renderer;
    var raycaster;
    this.orbitControls;
    this.povControls;
    this.controls;
    var lights;
    var canvas = document.getElementById("three-canvas");
    var controller;
    this.floorplan;

    this.routes = [];
    this.currentTool = null;

    //var canvas;
    //var canvasElement = canvasElement;

    this.needsUpdate = false;

    var lastRender = Date.now();
    var mouseOver = false;
    var hasClicked = false;

    var hud;

    this.heightMargin;
    this.widthMargin;
    this.elementHeight;
    this.elementWidth;

    this.itemSelectedCallbacks = $.Callbacks(); // item
    this.itemUnselectedCallbacks = $.Callbacks();



    this.toSync = []; // running list of html elements to overlay on 3d objs



    function init() {
      // ImageUtils no longer exists in r159 - but perhaps this needs to be set elsewhere?
//      THREE.ImageUtils.crossOrigin = "";

      scope.domElement = scope.element.get(0) // Container
      scope.activeCamera = scope.orbitCamera = new THREE.PerspectiveCamera(45, 1, 1, 10000);
      scope.tourCamera = new THREE.PerspectiveCamera(65, 1, 1, 10000);
      scope.tourCamera.setFocalLength(20)
      renderer = new THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true, // required to support .toDataURL()
        canvas: canvas
      });
      renderer.autoClear = false,
        renderer.shadowMapEnabled = true;
      renderer.shadowMapSoft = true;
      renderer.shadowMapType = THREE.PCFSoftShadowMap;



      


      //scope.domElement.appendChild(renderer.domElement);

      // handle window resizing
      scope.updateWindowSize();
      if (options.resize) {
        $(window).resize(scope.updateWindowSize);
      }










      scope.element.mouseenter(function () {
        mouseOver = true;
      }).mouseleave(function () {
        mouseOver = false;
      }).click(function () {
        hasClicked = true;
      });
      // new Model adds scene property to this scope
      scope.model = new Model(textureDir, scope);
      scope.scene = new Scene(scope.model, textureDir);
      scope.model.scene = scope.scene;
      scope.loader = scope.scene.loader;


      scope.hud = hud = new HUD(scope);

      scope.controls = new OrbitControls(scope.orbitCamera, scope.domElement);
      scope.controller = controller = new Controller(
        scope, scope.model, scope.activeCamera, scope.element, scope.controls, hud);
        scope.loader.load("./models/glb/wayMarker.glb", function(data) {
          scope.controller.wayMarker = data.scene.children[0];
          if(scope.routes.length) {
            for(let route of scope.routes) {
              for (let stop of route.stops) {
                if(!stop.children.length) {
                  stop.addMesh();
                }
              }
            }
          }
        });
        scope.centerCamera();
        scope.wallClicked = $.Callbacks(); // wall
        scope.floorClicked = $.Callbacks(); // floor
        scope.nothingClicked = $.Callbacks();
      scope.floorplan = new Floorplan(scope.scene,
        scope.model.floorplan, scope.controls);
        scope.model.floorplan.fireOnUpdatedRooms(scope.centerCamera);



      //canvas = new ThreeCanvas(canvasElement, scope);
            // setup camera nicely

            lights = new Lights(scope.scene, scope.model.floorplan);
            var skybox = new Skybox(scope.scene);
      scope.animate();
      window.addEventListener('keydown', scope.controller.onKeyDown, false);

      scope.changeViewerMode(scope.viewerModes.NORMAL);
      scope.clearSelected();

    }
    this.updateVisibleUnits = function() {
      if(scope.selected) {
        // should redisplay the context menu with updated units
        let tObj = scope.selected;
        tObj.setUnselected();
        tObj.setSelected();
      }
    }
    function cmToIn(cm) {
      return cm / 2.54;
    }
  
    function inToCm(inches) {
      return inches * 2.54;
    }
  
    this.correctUnits = function(val) {
      if(this.units === METRIC)
        return val;
      else
        return cmToIn(val);
    }
    this.unitSuffix = function() {
      if(this.units === METRIC) {
        return 'cm.';
      }
      else {
        return 'in.'
      }
    }
    this.changeViewerMode = function(val) {
      this.currentMode = val;
      let m = this.viewerModes;
      scope.controller.changeValidTargets('m', scope.currentMode);
      switch(val) {
        case m.NORMAL:
          scope.hud.changeControlScheme('main');
          if(scope.route)
            scope.route.hide();
          break;
        case m.WALLEDIT:
     //       scope.hud.showWallHeights();
            scope.hud.changeControlScheme('wallEdit');
            break;
  
          break;
        case m.ROUTEEDIT:
          this.hud.changeControlScheme("routeEdit");
          if(scope.route) {
            scope.route.show();
          }
          break;
        case m.ROUTEVIEW:
            scope.route.switchToTourView();
            this.hud.changeControlScheme("routeView");
            break;
        }
    }    
    this.swapControls = function(type) {
      return;
//      scope.controls.dispose();
      if(!type) {
        scope.controls = new OrbitControls(scope.orbitCamera, scope.domElement);
        options.spin = true;
      }
/*      else {
        scope.controls = new FirstPersonControls(scope.tourCamera, scope.domElement); 
        options.spin = false;
      } */
    }

    this.setSelected = function(object) {
      if(object === null) {
          this.itemUnselectedCallbacks.fire();
        if(this.controller.getState() === this.controller.states.SELECTED) {
          this.controller.switchState(this.controller.states.UNSELECTED);
        }
        this.selected = null;
      }
      
          // manage the selected object
      else if(object === this.selected) {
        return;
      }
      else {
		    if(this.selected) {
			    this.selected.setUnselected();
		    }
        this.selected = object;
        if (this.controller.getState() === this.controller.states.UNSELECTED) { // object !== null
          this.controller.switchState(this.controller.states.SELECTED);
        }
        if(object.isBP3DItem) {
          this.itemSelectedCallbacks.fire(object);
        }
      }
      scope.needsUpdate = true;
    }
    this.clearSelected = function() {
      this.selected ? this.selected.setUnselected() : null;   
    }
    
    function spin() {
      if (options.spin && !mouseOver && !hasClicked) {
        var theta = 2 * Math.PI * options.spinSpeed * (Date.now() - lastRender);
        scope.controls.rotateLeft(theta);
        scope.controls.update()
      }
    }

    this.loadRoutesFromSave = function(data) {
      for(let rData of data) {
        let tRoute = new ROUTE(this);
        tRoute.loadFromSaveData(rData)
        this.routes.push(tRoute);
      }
      if(this.routes.length)
        this.route = this.routes[0];
    }
    this.dataUrl = function () {
      var dataUrl = renderer.domElement.toDataURL("image/png");
      return dataUrl;
    }

    this.stopSpin = function () {
      hasClicked = true;
    }

    this.options = function () {
      return options;
    }

    this.getModel = function () {
      return scope.model;
    }

    this.getRoutesSaveData = function () {
      var routeDat = [];
      for(let route of this.routes) {
        var wpDat = [];
        for(let wp of route.stops) {
          wpDat.push(wp.position);
        }
        routeDat.push(wpDat);
      }
      return routeDat;
    }

    this.getScene = function () {
      return scope.scene;
    }

    this.getController = function () {
      return controller;
    }

    this.getCamera = function () {
      return scope.activeCamera;
    }

    function shouldRender() {
      // Do we need to draw a new frame
      if (scope.controls.needsUpdate || controller.needsUpdate || scope.needsUpdate || scope.scene.needsUpdate || controller.drawing || TWEEN) {
        scope.controls.needsUpdate = false;
        controller.needsUpdate = false;
        scope.needsUpdate = false;
        scope.scene.needsUpdate = false;
        return true;
      } else {
        return false;
      }
    }
    const tempVec = new THREE.Vector3();
    this.syncHTMLToObj = function(obj) {
      this.doSyncHTMLToObj(obj.labelFocus, obj.label.elem);
    }
    this.doSyncHTMLToObj = function(obj, elem) {
      if(obj.labelPosition) {
        tempVec.copy(obj.labelPosition);
      }
      else if(obj instanceof HalfEdge) {
        tempVec.copy(obj.labelPosition);
      }
      else {
          obj.updateWorldMatrix(true, false);
          obj.getWorldPosition(tempVec);
      }
      // get the normalized screen coordinate of that position
      // x and y will be in the -1 to +1 range with x = -1 being
      // on the left and y = -1 being on the bottom
      tempVec.project(scope.activeCamera);

      // convert the normalized position to CSS coordinates
      const x = (tempVec.x *  .5 + .5) * canvas.clientWidth;
      const y = (tempVec.y * -.5 + .5) * canvas.clientHeight ;//+ canvas.clientHeight / 5;

      // move the elem to that position
      elem.style.transform = `translate(${x}px,${y}px)`;

    }
    let clock = new THREE.Clock();
    function render() {
      spin();
      if (shouldRender() || scope.forceARender) {
        scope.forceARender = false;
        stats.begin();
        renderer.clear();
        renderer.render(scope.scene.getScene(), scope.activeCamera);
        renderer.clearDepth();
        renderer.render(hud.getScene(), scope.activeCamera);
        for(let sync of scope.toSync) {
          scope.syncHTMLToObj(sync);          
        }
        lights.updateShadowCamera();
        stats.end();
        if(scope.currentMode === scope.viewerModes.ROUTEVIEW) {
          scope.route.updateTweens();
        }
      /*  if(scope.povControls.enabled)
          scope.povControls.update(clock.getDelta); */

      }
      lastRender = Date.now();
    };

    this.animate = function() {
      var delay = 50;
      setTimeout(function () {
        requestAnimationFrame(scope.animate.bind(scope));
      }, delay);
      render();
    };

    this.rotatePressed = function () {
      controller.rotatePressed();
    }

    this.rotateReleased = function () {
      controller.rotateReleased();
    }

    this.setCursorStyle = function (cursorStyle) {
      scope.domElement.style.cursor = cursorStyle;
    };
    var mainControls = document.getElementById("main-controls");
    this.updateWindowSize = function () {
      // this appears to just be for the 3d scene - not for the floorplanner -
      // so we should be able to alter it just to pertain to three.js context
      let elem = renderer.domElement;
      var width = elem.parentNode.offsetWidth;
      var height = window.innerHeight - mainControls.offsetHeight;
       elem.parentNode.offsetHe
      scope.activeCamera.aspect = width / height;

  
      scope.heightMargin = scope.element.offset().top;
      scope.widthMargin = scope.element.offset().left;
//      scope.elementWidth = scope.element.innerWidth();
//      if (options.resize) {
//        scope.elementHeight = window.innerHeight - scope.heightMargin;
//      } else {
//        scope.elementHeight = scope.element.innerHeight();
//      }

//      camera.aspect = scope.elementWidth / scope.elementHeight;
        renderer.setSize(width, height);
        scope.activeCamera.updateProjectionMatrix();
//        camera.updateProjectionMatrix();

  //    renderer.setSize(scope.elementWidth, scope.elementHeight);
      
      scope.needsUpdate = true;
    }

    this.centerCamera = function () {
      var yOffset = 150.0;

      var pan = scope.model.floorplan.getCenter();
      pan.y = yOffset;

      scope.controls.target = pan;

      var distance = scope.model.floorplan.getSize().z * 1.5;

      var offset = pan.clone().add(
        new THREE.Vector3(0, distance, distance));
      //scope.orbitControls.setOffset(offset);
      scope.activeCamera.position.copy(offset);

      scope.controls.update();
    }

    // projects the object's center point into x,y screen coords
    // x,y are relative to top left corner of viewer
    this.projectVector = function (vec3, ignoreMargin) {
      ignoreMargin = ignoreMargin || false;

      var widthHalf = scope.elementWidth / 2;
      var heightHalf = scope.elementHeight / 2;

      var vector = new THREE.Vector3();
      vector.copy(vec3);
      vector.project(scope.activeCamera);

      var vec2 = new THREE.Vector2();

      vec2.x = (vector.x * widthHalf) + widthHalf;
      vec2.y = - (vector.y * heightHalf) + heightHalf;

      if (!ignoreMargin) {
        vec2.x += scope.widthMargin;
        vec2.y += scope.heightMargin;
      }

      return vec2;
    }

    init();
  }

  export function dispose3(obj) {
    if(obj.parent) {
      obj.parent.remove(obj);
    }

    var children = obj.children;
    var child;

    while(obj.children.length) {
            child = children[0];
            dispose3(child);
        
    }

    var geometry = obj.geometry;
    var material = obj.material;

    if (geometry) {
        geometry.dispose();
    }

    if (material) {
        if (material.aoMap) material.aoMap.dispose();
        if (material.bumpMap) material.bumpMap.dispose();
        if (material.emissiveMap) material.emissiveMap.dispose();
        if (material.normalMap) material.normalMap.dispose();
        if (material.roughnessMap) material.roughnessMap.dispose();
        if (material.metalnessMap) material.metalnessMap.dispose();
        if (material.displacementMap) material.displacementMap.dispose();
        if (material.alphaMap) material.alphaMap.dispose();

        material.dispose();
    }
}

import * as THREE from 'three';
import { dispose3 } from "./main";
import { Utils } from '../core/utils';
import { ROUTE, STOP } from './route';
import { Wall } from '../model/wall'

class PIN extends THREE.Mesh {
  sphere: THREE.Mesh;
    constructor() {
        super(new THREE.ConeGeometry(0.1, 1, 3, 2), new THREE.MeshPhongMaterial());
        this.sphere = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshPhongMaterial({color: 0xff0000}));
    }
}
var scope;
var three;
var hud;
var mouse;
export function passControllerScopeToTools(tScope) {
  scope = tScope;
  three = tScope.three;
  hud = tScope.three.hud;
  mouse = tScope.mouse;
}
function wallToolHover(inter) {
  if(inter.length && inter[0].object as any) {
    if(inter[0].object.wall) {
      let wall = inter[0];
      wall.edge.mouseOver();
    }
  }
}

function wallToolStart(inter) {
  
  if(!inter.length)
    return;
  if(inter.length && inter[0].object as any) {
    if(inter[0].object.wall) {
      let wall = inter[0];
      wall.edge.mouseOver();
    }
  }
  let point = inter[0].point;
  scope.tempLine = three.tempLine;
  scope.tempLine.renderOrder = 1;
  scope.tempLine.depthTest = false;
  scope.tempStartSphere = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshPhongMaterial({ color: "red"}));
  scope.toolTempObjects.push(scope.tempLine, scope.tempStartSphere);
  three.getScene().add(scope.tempLine);
  three.getScene().add(scope.tempStartSphere);
  scope.tempStartSphere.position.copy(point);
  scope.tempLine.geometry.attributes.position.setXYZ(0, point.x, point.y, point.z);
  scope.tempLine.geometry.needsUpdate = true;
  scope.setDrawing(true);
  three.controls.enabled = false;
  hud.addwallElement(scope.tempLine);
  three.toSync.push(scope.tempLine);
}
function wallToolDrag() {
  let inter = scope.getIntersections(
    three.controller.currentMouse,
    scope.validTargets,
    false, false, true);
    if(inter && inter.length) {
      let attr = scope.tempLine.geometry.getAttribute('position')
      attr.setXYZ(1, inter[0].point.x, inter[0].point.y, inter[0].point.z);
      attr.needsUpdate = true;
      scope.tempLine.geometry.needsUpdate = true;
      let vec1 = new THREE.Vector3().fromBufferAttribute(attr, 0);
      let vec2 = new THREE.Vector3().fromBufferAttribute(attr, 1);
      let thisValue = vec1.distanceTo(vec2);
      thisValue = three.correctUnits(thisValue);

      scope.tempLine.htmlElem.innerHTML = 
      thisValue.toFixed(2) + (three.units ? " cm" : " in");
      scope.tempLine.labelPosition = vec1.lerp(vec2, 0.5);
    }


}

function wallToolStop() {
  while(scope.toolTempObjects.length) {
    let tObj = scope.toolTempObjects.pop();
    dispose3(tObj);
    scope.setDrawing(false);
    three.controls.enabled = true;
    
    scope.tempLine.htmlElem.remove();
    Utils.removeValue(three.toSync, scope.tempLine);
    dispose3(scope.tempLine);

  }
  
}
export var WallTool = {
  hover: wallToolHover,
  start: wallToolStart,
  drag: wallToolDrag,
  stop: wallToolStop,
};

function rulerToolStart(inter) {
    if(!inter.length)
      return;
    let point = inter[0].point;
    scope.tempLine = three.tempLine;
    scope.tempLine.renderOrder = 1;
    scope.tempLine.depthTest = false;
    scope.tempStartSphere = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshPhongMaterial({ color: "red"}));
    scope.toolTempObjects.push(scope.tempLine, scope.tempStartSphere);
    three.getScene().add(scope.tempLine);
    three.getScene().add(scope.tempStartSphere);
    scope.tempStartSphere.position.copy(point);
    scope.tempLine.geometry.attributes.position.setXYZ(0, point.x, point.y, point.z);
    scope.tempLine.geometry.needsUpdate = true;
    scope.setDrawing(true);
    three.controls.enabled = false;
    hud.addRulerElement(scope.tempLine);
}
function rulerToolDraw() {
    let inter = scope.getIntersections(
      three.controller.currentMouse,
      scope.validTargets,
      false, false, true);
      if(inter && inter.length) {
        let attr = scope.tempLine.geometry.getAttribute('position')
        attr.setXYZ(1, inter[0].point.x, inter[0].point.y, inter[0].point.z);
        attr.needsUpdate = true;
        scope.tempLine.geometry.needsUpdate = true;
        let vec1 = new THREE.Vector3().fromBufferAttribute(attr, 0);
        let vec2 = new THREE.Vector3().fromBufferAttribute(attr, 1);
        let thisValue = vec1.distanceTo(vec2);
        if(three.units === 0) {
          thisValue = window.three.correctUnits(thisValue);
        }

        scope.tempLine.htmlElem.innerHTML = 
        thisValue.toFixed(2) + (three.units ? " cm" : " in");
        scope.tempLine.labelPosition = vec1.lerp(vec2, 0.5);
        window.three.doSyncHTMLToObj(scope.tempLine, scope.tempLine.htmlElem)
      }
  }

function rulerToolStop() {
    while(scope.toolTempObjects.length) {
      let tObj = scope.toolTempObjects.pop();
      dispose3(tObj);
      scope.setDrawing(false);
      three.controls.enabled = true;
      
      scope.tempLine.htmlElem.remove();
      dispose3(scope.tempLine);

    }
    
  }
  export var RulerTool = {
    start: rulerToolStart,
    draw: rulerToolDraw,
    stop: rulerToolStop,
  };

  function addFacing(inter) {
    if(!three.route) {
      alert("Please place a stop in the route first using the button above.");
      return;
    }

    
  }

  function addStop(inter) {
    if(!inter.length)
    return;
  let point = inter[0].point;

  if(!three.route) {
    let route = new ROUTE(three);
    three.route = route;
    three.routes.push(route);
  }

  let stop = three.route.addStop(new THREE.Vector3().set(point.x, 32, point.z));

  three.getScene().scene.add(stop);

  if(stop.last && stop.last.line) {
    three.getScene().scene.add(stop.last.line);
  }
  three.forceARender = true;
}

function selectStop(inter) {
  if(inter.length && inter[0].object) {
    let parent = inter[0].object.parent;
    while(parent) {
      if(parent instanceof STOP) {
        parent.setSelected();
      }
      parent = parent.parent;
    }
  }
}

function removeStop(inter) {
  if(inter.length && inter[0].object) {
    let parent = inter[0].object.parent;
    while(parent) {
      if(parent instanceof STOP) {
        parent.removeMe();
      }
      parent = parent.parent;
    }
  }
}  

function removeFacing(inter) {
}

function TourStopToolDraw() {
    let inter = scope.getIntersections(
      three.controller.currentMouse,
      scope.validTargets,
      false, false, true);
      if(inter && inter.length) {
        let attr = three.route.tempLine.geometry.getAttribute('position')
        attr.setXYZ(1, inter[0].point.x, inter[0].point.y, inter[0].point.z);
        attr.needsUpdate = true;
        three.route.tempLine.geometry.needsUpdate = true;
        let vec1 = new THREE.Vector3().fromBufferAttribute(attr, 0);
        let vec2 = new THREE.Vector3().fromBufferAttribute(attr, 1);
      }


  }
function TourStopToolStop() {
    while(scope.toolTempObjects.length) {
      let tObj = scope.toolTempObjects.pop();
      dispose3(tObj);
      scope.setDrawing(false);
      three.controls.enabled = true;
      
      scope.tempLine.htmlElem.remove();
      Utils.removeValue(three.toSync, scope.tempLine);
      dispose3(scope.tempLine);

    }
    
  }
  export var TourStopTool = {
    selectStop: selectStop,
    addStop: addStop,
    addFacing: addFacing,
    removeStop: removeStop,
    removeFacing: removeFacing,
    draw: TourStopToolDraw,
    stop: TourStopToolStop,
  };

  function cmToIn(cm) {
    return cm / 2.54;
  }

  function inToCm(inches) {
    return inches * 2.54;
  }

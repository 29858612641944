// htmlElems.ts -- for javascript-generated html elements

import { Utils } from '../core/utils'

export class LABEL {
    span: HTMLSpanElement;
    inputElement: HTMLInputElement;
    elem: HTMLDivElement;
    parent: any;
    type: any;

    // The label's target is the three.object3D or derivitive class that actually determines the label's position.

    constructor(parent, type, labelFocus?, text?) {
        // elem = root elem
        this.parent = parent;
        this.type = type;
        if(!labelFocus) {
            labelFocus = parent;
        }
        
        this.createHTMLStructure(type, text);
        /*
        if(type !== 'w') {
            parent.labelFocus = labelFocus;
        }
*/
        this.parent = parent;
        parent.label = this;

    }
    hide() {
        this.elem.remove();
        Utils.removeValue(window.three.toSync, this.parent);
    }
    show() {
        document.body.appendChild(this.elem);
        window.three.toSync.push(this.parent);
        if(this.type === 'w') {
            this.parent.updateLabelValue();
        }    
    }

    createHTMLStructure(option, text) {
        this.elem = document.createElement('div');
        switch(option) {
            case 'w': // wall height labels
                this.span = document.createElement('span');
                this.inputElement = document.createElement('input');
                this.elem.className = "heightInputDiv";
                this.inputElement.className = "heightInput";
                this.span.className = "heightInputSpan";
        
                this.inputElement.size = 4;
            
                this.elem.appendChild(this.span);
                this.elem.appendChild(this.inputElement);
                this.span.innerHTML = "Height:";
                break;
            case 'p': // position labels
                this.span = document.createElement('span');
                this.elem.appendChild(this.span);

                this.elem.className = "labelDiv";
                this.span.className = "labelSpan";
    
                this.span.innerHTML = text;
                break;

            default:
                this.span = document.createElement('span');
                this.elem.appendChild(this.span);

                this.elem.className = "labelDiv";
                this.span.className = "labelSpan";
        
                this.span.innerHTML = text;
        
        }


    }
}

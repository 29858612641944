/**
Hud.ts should include visual ui components for 3d widgets and tools 
*/

import * as THREE from 'three'
import { Floorplan } from './floorPlan.js';
import { Utils } from '../core/utils'
import { Controller } from './controller';
  /**
   * Drawings on "top" of the scene. e.g. rotate arrows
   */
  export var HUD = function (three) {
    this.currentControlScheme = "main"
    var scope = this;
    var three = three;
    var scene = new THREE.Scene();

    var rotating = false;
    var mouseover = false;

    var tolerance = 10;
    var height = 5;
    var distance = 20;
    var color = "#ffffff";
    var hoverColor = "#f1c40f";

    var activeObject = null;

    this.mainToolbar = document.getElementsByClassName('mainToolbar')[0] as HTMLDivElement;
    this.routeToolbar = document.getElementsByClassName('routeToolbar')[0] as HTMLDivElement;
    this.mainToolbarButton = document.getElementById('toolsMenuButton') as HTMLButtonElement;
    this.cameraControlDiv= document.getElementById("camera-controls");
    this.shuttleControlDiv= document.getElementById("shuttleControlDiv");
    this.breadcrumbField = document.getElementById('breadcrumbField');

    // exits any active tool and cleans up tool ui
    this.backButton = document.createElement('img');
    this.backButton.id = "backButton";
    this.backButton.src = 'images/icons/exit.svg';
    three.domElement.appendChild(this.backButton);

    this.getScene = function () {
      return scene;
    }

    this.getObject = function () {
      return activeObject;
    }

    this.showWallHeights = function() {
      let walls = three.floorplan.edges;
      for (var i = 0; i < walls.length; i++) {
        walls[i].label ? walls[i].label.show() : null;
      }

    }
    this.hideWallHeights = function() {
      let walls = three.floorplan.edges;
      for (var i = 0; i < walls.length; i++) {
        walls[i].label ? walls[i].label.hide() : null;
      }
    }
    
    function showElem(elem) {
      elem.style.display = "block";
    }
    function hideElem(elem) {
      elem.style.display = "none";
    }
    this.changeControlScheme = function(arg) {
      hideElem(this.cameraControlDiv);
      hideElem(this.shuttleControlDiv);
      hideElem(this.mainToolbar);
      hideElem(this.mainToolbarButton);
      hideElem(this.routeToolbar);
      hideElem(this.backButton);
      (document.getElementById("pathButtonRemoveStop")).style.backgroundColor = "";
      (document.getElementById("pathButtonAddStop")).style.backgroundColor = "";


      this.currentControlScheme = arg;
      switch(arg) {
        case "main":
          showElem(this.mainToolbar);
          showElem(this.mainToolbarButton);
          break;
        case "routeEdit":
          showElem(this.routeToolbar);
          break;
        case "routeView":
          showElem(this.shuttleControlDiv);
          break;
        case "wallEdit":
        case "ruler":
          break;

      }
      if(arg !== "main") {
        showElem(this.backButton);
      }
    }
    this.addRulerElement = function(parent) {
      let elem = document.createElement('div');
      elem.id = "rulerMeasurementDiv";
      parent.htmlElem = elem;
      document.body.appendChild(elem);

    }


    function init() {
      three.itemSelectedCallbacks.add(itemSelected);
      three.itemUnselectedCallbacks.add(itemUnselected);
    }

    function resetSelectedItem() {
      three.clearSelected();
      if (activeObject) {
        scene.remove(activeObject);
        activeObject = null;
      }
    }

    function itemSelected(item) {
      if (three.selected != item) {
        resetSelectedItem();
        if (item.allowRotate && !item.fixed) {
          item.setSelected();
          activeObject = makeObject(three.selected);
          scene.add(activeObject);
        }
      }
    }

    function itemUnselected() {
      resetSelectedItem();
    }

    this.setRotating = function (isRotating) {
      rotating = isRotating;
      setColor();
    }

    this.setMouseover = function (isMousedOver) {
      mouseover = isMousedOver;
      setColor();
    }

    function setColor() {
      if (activeObject) {
        activeObject.children.forEach((obj) => {
          obj.material.color.set(getColor());
        });
      }
      three.needsUpdate = true;
    }

    function getColor() {
      return (mouseover || rotating) ? hoverColor : color;
    }

    this.update = function () {
      if (activeObject) {
        activeObject.rotation.y = three.selected.rotation.y;
        activeObject.position.x = three.selected.position.x;
        activeObject.position.z = three.selected.position.z;
      }
    }

    function makeLineGeometry(item) {
      let points = [new THREE.Vector3(0, 0, 0), rotateVector(item)];
      var geometry = new THREE.BufferGeometry().setFromPoints(points);
      return geometry;
    }

    function rotateVector(item) {
      var vec = new THREE.Vector3(0, 0,
        Math.max(item.halfSize.x, item.halfSize.z) + 1.4 + distance);
      return vec;
    }

    function makeLineMaterial(rotating) {
      var mat = new THREE.LineBasicMaterial({
        color: getColor(),
        linewidth: 3
      });
      return mat;
    }

    function makeCone(item) {
      var coneGeo = new THREE.CylinderGeometry(5, 0, 10);
      var coneMat = new THREE.MeshBasicMaterial({
        color: getColor()
      });
      var cone = new THREE.Mesh(coneGeo, coneMat);
      cone.position.copy(rotateVector(item));

      cone.rotation.x = -Math.PI / 2.0;

      return cone;
    }

    function makeSphere(item) {
      var geometry = new THREE.SphereGeometry(4, 16, 16);
      var material = new THREE.MeshBasicMaterial({
        color: getColor()
      });
      var sphere = new THREE.Mesh(geometry, material);
      return sphere;
    }

    function makeObject(item) {
      var object = new THREE.Object3D();
      var line = new THREE.LineSegments(
        makeLineGeometry(item),
        makeLineMaterial(scope.rotating));

      var cone = makeCone(item);
      var sphere = makeSphere(item);

      object.add(line);
      object.add(cone);
      object.add(sphere);

      object.rotation.y = item.rotation.y;
      object.position.x = item.position.x;
      object.position.z = item.position.z;
      object.position.y = height;

      return object;
    }

    init();
  }

/// <reference path="../../lib/jQuery.d.ts" />

// controller.ts 
// object manipulation mouse event handlers,
// tool mouse event handlers
import * as THREE from 'three'

import { Utils } from "../core/utils";

import { RulerTool, TourStopTool, passControllerScopeToTools } from "./tools"
import { STOP } from '../three/route';
import { Wall } from '../model/wall';

  export var Controller = function (three, model, camera, element, controls, hud) {

    this.tools = Object.freeze({
      WALLHEIGHT: 1,
      RULER: 2,
      ADDSTOP: 3,
      REMOVESTOP: 4,
      ADDFACING: 5,
      REMOVEFACING: 5
    });

    var scope = this;
    var controller = scope;
    var raycaster = new THREE.Raycaster();

    this.enabled = true;

    this.validTargets; // this holds the current valid target list for the tool or mode. 
    var three = three;
    this.three = three;
    three.controller = this;
    this.wayMarker;
    var model = model;
    var scene = three.scene;
    var element = element;
    this.drawing = false;
//    var nonJQueryElement = element[0];
    var nonJQueryElement;

    var camera = camera;
    var controls = controls;
    var hud = hud;

    var plane; // ground plane used for intersection testing

    var currentMouse;
    var intersectedObject;
    var mouseoverObject;
    this.clearState;
    this.mouseDown = false;
    var mouseMoved = false; // has mouse moved since down click

    var rotateMouseOver = false;

    var states = this.states = {
      UNSELECTED: 0, // no object selected
      SELECTED: 1, // selected but inactive
      DRAGGING: 2, // performing an action while mouse depressed
      ROTATING: 3,  // rotating with mouse down
      ROTATING_FREE: 4, // rotating with mouse up
      PANNING: 5
    };
    var state = states.UNSELECTED;



    this.toolTempObjects = [];
    this.toolTempLine = null;
    this.toolTempStartSphere = null;

    this.needsUpdate = true;

    function init() {
      nonJQueryElement = document.getElementById("three-canvas");
      three.hud.backButton.addEventListener('click', three.controller.doBackButton.bind(this) );

      element.mousedown(mouseDownEvent);
      element.mouseup(mouseUpEvent);
      element.mousemove(mouseMoveEvent);

      currentMouse = scope.currentMouse = new THREE.Vector2();

      scene.itemRemovedCallbacks.add(itemRemoved);
      scene.itemLoadedCallbacks.add(itemLoaded);
      setGroundPlane();

      passControllerScopeToTools(scope);

      (document.getElementById("modeButtonRouteEdit")).addEventListener('click', function() {

        three.changeViewerMode(three.viewerModes.ROUTEEDIT);
      });
      (document.getElementById("modeButtonTourView")).addEventListener('click', function() {
        three.changeViewerMode(three.viewerModes.ROUTEVIEW);
      });
      (document.getElementById("toolButtonRuler")).addEventListener('click', function() {
        useTool(scope.tools.RULER);
      });
      (document.getElementById("toolButtonWallHeight")).addEventListener('click', function() {
        three.changeViewerMode(three.viewerModes.WALLEDIT);
      }.bind(this));
      (document.getElementById("pathButtonAddStop")).addEventListener('click', function() {
        useTool(scope.tools.ADDSTOP);
      }.bind(this));
      (document.getElementById("pathButtonRemoveStop")).addEventListener('click', function() {
        useTool(scope.tools.REMOVESTOP);
      }.bind(this));
      (document.getElementById("pathButtonAddFacing")).addEventListener('click', function() {
        useTool(scope.tools.ADDFACING);
      }.bind(this));
    }
    this.setDrawing = function(arg) {
      scope.drawing = arg;
    }
    this.getState = function() {
      return state;
    }
    this.changeValidTargets = function(type, value) {
      let cT = three.currentTool;
      let cM = three.currentMode;
      let t = scope.tools;
      let m = three.viewerModes;
      let values = {
        DRAGGING: 1,
      }

      if(type === 't') {// matching with a tool
        switch(three.currentTool) {
          case t.RULER:
            scope.validTargets = model.floorplan.wallEdgePlanes();
            scope.validTargets = scope.validTargets.concat(model.floorplan.floorPlanes());
            scope.validTargets = scope.validTargets.concat(model.scene.getItems());
            break;
          case (t.ADDSTOP):
            scope.validTargets = model.floorplan.floorPlanes();
            scope.validTargets = scope.validTargets.concat(model.scene.getItems());
            break;
          case (t.REMOVESTOP):
            scope.validTargets = three.route.stops;
            break;
/*          case (t.ADDFACING):
            scope.validTargets = model.floorplan.floorPlanes();
            scope.validTargets = scope.validTargets.concat(model.scene.getItems());
            break;
          case (t.REMOVEFACING):
            scope.validTargets = three.route.stops;
            scope.validTargets = scope.validTargets.concat(model.scene.getItems());
            break;
  */
          
        }
      }
      else { // matching with a 'viewerMode'
        switch(three.currentMode) {
          case three.viewerModes.NORMAL:
            scope.validTargets = model.scene.getItems();            
            break;
          case three.viewerModes.ROUTEEDIT:
            if(value === values.DRAGGING) {
              scope.validTargets = [plane];
              //scope.validTargets = model.floorplan.floorPlanes();
            }
            else {
              scope.validTargets = three.route.stops;
            }
            break;
          case three.viewerModes.ROUTEVIEW:
            scope.validTargets = [];
            break;
          case three.viewerModes.WALLEDIT:
            if(scope.mouseDown) { // we are dragging a wall size
              scope.validTargets = model.floorplan.wallEdgePlanes();
            }
            else {
              scope.validTargets = model.floorplan.wallEdgePlanes();
            }
            break;
        }

      }


    }
    function useTool(tool) {
      hud.backButton.style.display = "block";
      three.currentTool = tool;
      
      switch(tool) {
        case scope.tools.RULER:
          three.setCursorStyle("crosshair");
          scope.changeValidTargets('t', three.currentTool);
          three.hud.changeControlScheme('ruler');
          break;

        case scope.tools.ADDSTOP:
          document.body.style.cursor = 'url("images/icons/removeCursor.svg")';
          scope.changeValidTargets('t', three.currentTool);
          (document.getElementById("pathButtonAddStop")).style.backgroundColor = "yellow";
          break;
        case scope.tools.REMOVESTOP:
          document.body.style.cursor = 'images/icons/removeCursor.svg';
          scope.changeValidTargets('t', three.currentTool);
          (document.getElementById("pathButtonRemoveStop")).style.backgroundColor = "yellow";


          break;
              // highlight button
        case scope.tools.ADDFACING:
          document.body.style.cursor = 'images/icons/addCursor.svg';
          break;
        case scope.tools.REMOVEFACING:
          document.body.style.cursor = 'url("images/icons/removeCursor.svg")';

                  // highlight button
          break;

      }
  
    }
    this.onKeyDown = function(e) {
      let keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40, h: 72, v: 86, r: 82, c: 67, m: 77, ESC: 27};
      switch(e.keyCode) {
        case keys.m: // measure
          useTool(scope.tools.RULER);
          break;
        case keys.c: 
          useTool(scope.tools.TOURVIEW);
          break;
        case keys.r:
          useTool(scope.tools.ROUTEVIEW);
          break;
        case keys.h:
          three.changeViewerMode(three.viewerModes.WALLEDIT);
          break;
        case keys.ESC:
          scope.doCancel();
          break;
      }
    }

    function releaseTool() {

      switch(three.currentTool) {
        case scope.tools.RULER:
          break;
        case scope.tools.ADDSTOP:
        case scope.tools.ADDFACING:
      }
      three.currentTool = null;
      three.setCursorStyle("pointer");
    }
    this.doCancel = function() {
      this.doBackButton();
    }
    this.doBackButton = function() {

      if(!three.currentTool) { // if we're clicking 'back' in one of the other modes
        if(three.currentMode === three.viewerModes.ROUTEEDIT) {
          three.route.hide();
        }
        else if(three.currentMode === three.viewerModes.WALLEDIT) {
          three.hud.hideWallHeights();  
        }
        else if(three.currentMode === three.viewerModes.ROUTEVIEW) {
          three.route.switchFromTourView();
          three.changeViewerMode(three.viewerModes.NORMAL);
        }      
          if(three.selected) {
          three.selected.setUnselected();
        }
        if(three.highlit) {
          three.highlit.updateHighlight();
        }
        three.changeViewerMode(three.viewerModes.NORMAL);
      }

      else if(three.currentTool === three.controller.tools.ADDSTOP ||
        three.currentTool === three.controller.tools.REMOVESTOP) {
          three.changeViewerMode(three.viewerModes.ROUTEEDIT);
          releaseTool();
      }
      else {
        if(three.currentTool) {
          releaseTool();
        }

        switch(three.hud.currentControlScheme) {
          case "shuttle":
          case "routeEdit":
          case "wallEdit":
          case "ruler":
            three.changeViewerMode(three.viewerModes.NORMAL);
        }
      }

      
    }
    // invoked via callback when item is loaded
    function itemLoaded(item) {
      if (!item.position_set) {
        three.setSelected(item);
       switchState(states.DRAGGING);
        var pos = item.position.clone();
        pos.y = 0;
        var vec = three.projectVector(pos);
        clickPressed(vec);
      }
      item.position_set = true;
    }

    function clickPressed(vec2?) {
      vec2 = vec2 || currentMouse;
      if(!three.selected) {
        console.log('no selected')
      }
      else if(three.selected.isBP3DItem) {
        var intersection = scope.itemIntersection(currentMouse, three.selected);
        if (intersection) {
          three.selected.clickPressed(intersection);
        }
      }
    }

    function clickDragged(vec2?) {
      vec2 = vec2 || currentMouse;
      if(three.selected.isBP3DItem) {
        var intersection = scope.itemIntersection(currentMouse, three.selected);
        if (intersection) {
          if (scope.isRotating()) {
            three.selected.rotate(intersection);
          } else {
            three.selected.clickDragged(intersection);
          }
        }
      }
    }

    function itemRemoved(item) {
      // invoked as a callback to event in Scene
      if (item === three.selected) {
        item.setUnselected();
        item.mouseOff();
      }
    }

    function setGroundPlane() {
      // ground plane used to find intersections
      var size = 10000;
      plane = new THREE.Mesh(
        new THREE.PlaneGeometry(size, size),
        new THREE.MeshBasicMaterial());
      plane.rotation.x = -Math.PI / 2;
      plane.visible = false;
      plane.name = "greaterGroundPlane"
      scene.add(plane);
    }

    function checkWallsAndFloors(event?) {

      // double click on a wall or floor brings up texture change modal
      if (state == states.UNSELECTED && mouseoverObject == null) {
        // check walls
        var wallEdgePlanes = model.floorplan.wallEdgePlanes();
        var wallIntersects = scope.getIntersections(
          currentMouse, wallEdgePlanes, true);
        if (wallIntersects.length > 0) {
          var wall = wallIntersects[0].object.edge;
          three.wallClicked.fire(wall);
          return;
        }

        // check floors
        var floorPlanes = model.floorplan.floorPlanes();
        var floorIntersects = scope.getIntersections(
          currentMouse, floorPlanes, false);
        if (floorIntersects.length > 0) {
          var room = floorIntersects[0].object.room;
          three.floorClicked.fire(room);
          return;
        }

        three.nothingClicked.fire();
      }

    }

    function setMouse(e, m) {
        currentMouse.x = (e.offsetX / nonJQueryElement.clientWidth ) * 2 - 1;
        currentMouse.y = -(e.offsetY / nonJQueryElement.clientHeight ) * 2 + 1;
//        return x; //e.offsetX - rect.left; 
    }
    function mouseMoveEvent(event) {
      if (scope.enabled) {
        event.preventDefault();

        mouseMoved = true;
        setMouse(event, currentMouse);

        if (!scope.mouseDown) {
          updateIntersections();
        }
        if(scope.drawing) {
          if(three.currentTool === scope.tools.RULER) {
              RulerTool.draw();          
          }
          else if(three.currentMode === three.viewerModes.ROUTEEDIT ) {
            three.selected.drag();          
          }
          else if(three.currentMode === three.viewerModes.WALLEDIT ) {
            three.selected.drag();          
          }
          return;
        }
        switch (state) {
          case states.UNSELECTED:
            updateMouseover();
            break;
          case states.SELECTED:
            updateMouseover();
            break;
          case states.DRAGGING:
          case states.ROTATING:
          case states.ROTATING_FREE:
            clickDragged();
            hud.update();
            scope.needsUpdate = true;
            break;
        }
      }
    }

    this.isRotating = function () {
      return (state == states.ROTATING || state == states.ROTATING_FREE);
    }
    function mouseDownEvent(event) {
      if (scope.enabled) {
        event.preventDefault();

        mouseMoved = false;
        scope.mouseDown = true;
        let inter = scope.getIntersections(
          currentMouse,
          scope.validTargets,
          false, false, true);

        switch(three.currentTool) {
          case null:
            break;
          case scope.tools.RULER:
            if(inter)
              RulerTool.start(inter);
            return;
          case scope.tools.REMOVEFACING:
          case scope.tools.REMOVESTOP:
          case scope.tools.ADDFACING:
          case scope.tools.ADDSTOP:
            return;
        }
        switch (state) {
          case states.SELECTED:
            if (rotateMouseOver) {
             switchState(states.ROTATING);
            } else if (intersectedObject != null) {
              intersectedObject.setSelected();
              if(intersectedObject instanceof STOP) {
                switchState(states.DRAGGING);
                intersectedObject.startDrag();
              }
              else if(three.viewerMode === three.viewerModes.WALLEDIT &&
                three.selected instanceof Wall) {
                  switchState(states.DRAGGING);
                three.selected.startDrag(currentMouse);
              }
              else if (!intersectedObject.fixed) {
               switchState(states.DRAGGING);
              }
            }
            break;
          case states.UNSELECTED:
            if (intersectedObject != null) {
              if(three.currentTool !== scope.tools.REMOVESTOP) {
                intersectedObject.setSelected();
                if(three.selected instanceof Wall) {
                  three.selected.setSelected();
                  switchState(states.DRAGGING);
                  three.selected.startDrag(inter); 
                } 
                else if(intersectedObject instanceof STOP) {
                  intersectedObject.setSelected();
                  switchState(states.DRAGGING);
                  intersectedObject.startDrag();
                }
                else if(!intersectedObject.fixed) {
                 switchState(states.DRAGGING);
                }
              }
            }
            break;
          case states.DRAGGING:
          case states.ROTATING:
            break;
          case states.ROTATING_FREE:
           switchState(states.SELECTED);
            break;
        }
      }
    }

    function mouseUpEvent(event) {
      if (scope.enabled) {
        scope.mouseDown = false;

        if(three.currentTool === scope.tools.RULER) {
              RulerTool.stop();
              return;             
        
        }
          let inter = scope.getIntersections(
            currentMouse,
            scope.validTargets,
            false, false, true);

          switch(three.currentTool) {
              case null:
                break;
              case scope.tools.REMOVEFACING:
                if(inter)
                  TourStopTool.removeFacing(inter);
                return;
              case scope.tools.REMOVESTOP:
                if(inter)
                  TourStopTool.removeStop(inter);
                return;

              case scope.tools.ADDFACING:
                if(inter)
                  TourStopTool.addFacing(inter);
                return;
             case scope.tools.ADDSTOP:
                if(inter)
                  TourStopTool.addStop(inter);
                return;
          }
          switch (state) {
            case states.DRAGGING:
              if(!three.selected) {
               switchState(states.UNSELECTED); // not sure why this can't happen upon item deletion - but in some 
                                                // cases it fails to fire.  Maybe a jquery callback issue when the window loses focus?
              }
              else {
                if(three.selected instanceof STOP) {
                  three.selected.stopDrag();
                  switchState(states.SELECTED);
                }
                else if(three.selected instanceof Wall) {
                  three.selected.stopDrag();
                  switchState(states.UNSELECTED);
                }
                else {
                  three.selected.clickReleased();
                  switchState(states.SELECTED);
                }

                
              }
              break;
            case states.ROTATING:
              if (!mouseMoved) {
               switchState(states.ROTATING_FREE);
              } else {
               switchState(states.SELECTED);
              }
              break;
            case states.UNSELECTED:
              if(three.currentMode === three.viewerModes.ROUTEEDIT) {
                if(inter)
                  TourStopTool.selectStop(inter);
                return;
              }
              else if(three.currentMode === three.viewerModes.TOURVIEW) {
                return;
              }

              if (!mouseMoved) {
                checkWallsAndFloors();
              }
              break;
            case states.SELECTED:
              if (intersectedObject == null && !mouseMoved) {
                three.clearSelected();
                checkWallsAndFloors();
              }
              break;
            case states.ROTATING_FREE:
              break;
              
        
      }
    }
  }
    var switchState = this.switchState = function(newState) {
     // console.log(newState);
      if (newState != state) {
        onExit(state);
        onEntry(newState);
      }
      state = newState;
      hud.setRotating(scope.isRotating());
    }

    this.clearState = function() {
     switchState(states.UNSELECTED)
    }
     
    function onEntry(state) {
      switch (state) {
        case states.UNSELECTED:
//          three.selected ? three.selected.setUnselected(): null;
        case states.SELECTED:
          controls.enabled = true;
          break;
        case states.ROTATING:
        case states.ROTATING_FREE:
          controls.enabled = false;
          break;
        case states.DRAGGING:
          three.setCursorStyle("move");
/*          if(three.selected instanceof Wall) {
            three.selected.startDrag(currentMouse);
          }
          else { */
            clickPressed();
//          }
          controls.enabled = false;
          break;
      }
    }

    function onExit(state) {
      switch (state) {
        case states.UNSELECTED:
        case states.SELECTED:
          break;
        case states.DRAGGING:
          if (mouseoverObject) {
            three.setCursorStyle("pointer");
          } else {
            three.setCursorStyle("auto");
          }
          break;
        case states.ROTATING:
        case states.ROTATING_FREE:
          break;
      }
    }
    
    // updates the vector of the intersection with the plane of a given
    // mouse position, and the intersected object
    // both may be set to null if no intersection found
    function updateIntersections() {

      // check the rotate arrow
      var hudObject = hud.getObject();
      if (hudObject != null) {
        var hudIntersects = scope.getIntersections(
          currentMouse,
          hudObject,
          false, false, true);
        if (hudIntersects.length > 0) {
          rotateMouseOver = true;
          hud.setMouseover(true);
          intersectedObject = null;
          return;
        }
      }
      rotateMouseOver = false;
      hud.setMouseover(false);

      // check objects
      var items;
      var intersects;
      if(three.currentMode === three.viewerModes.WALLEDIT) {
        items = model.floorplan.wallEdgePlanes();
        intersects = scope.getIntersections(
          currentMouse,
          items,
          true);


      }
      else {
        if(three.currentMode === three.viewerModes.ROUTEEDIT) {
          items = three.route.stops ? three.route.stops : [];
        }
        else {
          items = model.scene.getItems();
        }

        intersects = scope.getIntersections(
          currentMouse,
          items,
          false, true, true);
      }
      if (intersects.length > 0) {
        intersectedObject = intersects[0].object;
      } else {
        intersectedObject = null;
      }

      if(intersectedObject) {
        if(three.currentMode === three.viewerModes.WALLEDIT) {
          intersectedObject = intersectedObject.edge;
        }
        else {
          while(!intersectedObject.isBP3DItem && !intersectedObject.isDatapointItem) {
            intersectedObject = intersectedObject.parent;
            if(!intersectedObject) {
              console.log('Intersected object has parent that was null.')
              // this shouldn't happen
              break;
            }
            if(intersectedObject.type === "Scene") {
              intersectedObject = null;
              break;
            }
          }
        }
      }
    }

    // sets coords to -1 to 1
    function normalizeVector2(vec2) {
      var retVec = new THREE.Vector2();
      retVec.x = ((vec2.x - three.widthMargin) / (window.innerWidth - three.widthMargin)) * 2 - 1;
      retVec.y = -((vec2.y - three.heightMargin) / (window.innerHeight - three.heightMargin)) * 2 + 1;
      return retVec;
    }
//    ( ( event.clientX - canvasBounds.left ) / ( canvasBounds.right - canvasBounds.left ) ) * 2 - 1;
    //
    function mouseToVec3(vec2) {
     // var normVec2 = normalizeVector2(vec2)
      var normVec2 = vec2;
      var vector = new THREE.Vector3(
        normVec2.x, normVec2.y, 0.5);
      vector.unproject(camera);
      return vector;
    }

    // returns the first intersection object
    this.itemIntersection = function (vec2, item) {
      var customIntersections = item.customIntersectionPlanes();
      var intersections = null;
      if (customIntersections && customIntersections.length > 0) {
        intersections = this.getIntersections(vec2, customIntersections, true);
      } else {
        intersections = this.getIntersections(vec2, plane);
      }
      if (intersections.length > 0) {
        return intersections[0];
      } else {
        return null;
      }
    }

    // filter by normals will only return objects facing the camera
    // objects can be an array of objects or a single object
    this.getIntersections = function (vec2, objects, filterByNormals, onlyVisible, recursive, linePrecision) {
      if(objects === undefined) {
        console.log('error: undefined intersection objects.')
        return [];
      }
/*
      var x = this.__mouse.x = ( e.offsetX / this.__renderer.domElement.clientWidth ) * 2 - 1;
      var y = this.__mouse.y =  - ( e.offsetY / this.__renderer.domElement.clientHeight ) * 2 + 1;
  
      this.__raycaster.setFromCamera( {x: x, y: y}, this.__camera );
      */
      var vector = mouseToVec3(vec2);

      onlyVisible = onlyVisible || false;
      filterByNormals = filterByNormals || false;
      recursive = recursive || false;
      linePrecision = linePrecision || 20;


 //     raycaster.setFromCamera(vec2, camera)
      var direction = vector.sub(camera.position).normalize();
      var raycaster = new THREE.Raycaster(
        camera.position,
        direction); 
      raycaster.params.Mesh.precision = linePrecision;
      var intersections;
      if (objects instanceof Array) {
        intersections = raycaster.intersectObjects(objects, recursive);
      } else {
        intersections = raycaster.intersectObject(objects, recursive);
      }
      // filter by visible, if true
      if (onlyVisible) {
        intersections = Utils.removeIf(intersections, function (intersection) {
          return !intersection.object.visible;
        });
      }

      // filter by normals, if true
      if (filterByNormals) {
        intersections = Utils.removeIf(intersections, function (intersection) {
          var dot = intersection.face.normal.dot(direction);
          return (dot > 0)
        });
      }
      return intersections;
    }


    // TODO: there MUST be simpler logic for expressing this
    function updateMouseover() {
      if (intersectedObject != null) {
        if (mouseoverObject != null) {
          if (mouseoverObject !== intersectedObject) {
            mouseoverObject.mouseOff();
            mouseoverObject = intersectedObject;
            mouseoverObject.mouseOver();
            scope.needsUpdate = true;
          } else {
            // do nothing, mouseover already set
          }
        } else {
          mouseoverObject = intersectedObject;
          mouseoverObject.mouseOver();
          three.setCursorStyle("pointer");
          scope.needsUpdate = true;
        }
      } else if (mouseoverObject != null) {
        mouseoverObject.mouseOff();
        three.setCursorStyle("auto");
        mouseoverObject = null;
        scope.needsUpdate = true;
      }
    }

    init();
  }


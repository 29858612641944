import * as THREE from 'three'
/// <reference path="../../lib/jQuery.d.ts" />

import { Configuration, configWallThickness, configWallHeight } from "../core/configuration";
import { Utils } from "../core/utils";
import { Item } from "../items/item";
import { Corner } from "./corner";
import { HalfEdge } from "./half_edge";
import { LABEL } from '../three/htmlElems';



  /** The default wall texture. */
  const defaultWallTexture = {
    url: "rooms/textures/wallmap.png",
    stretch: true,
    scale: 0
  }

  /** 
   * A Wall is the basic element to create Rooms.
   * 
   * Walls consists of two half edges.
   *
   * Note: the frontEdge, backEdge, and intersection planes for selecting and changing height appear to still
   * have vertices in world coordinates.  Wall.mesh should now have walls with vertices in local space, with a transform.
   */
  export class Wall {
    public wallHeightPlane = null;
    public isWall : boolean = true;
    /** The unique id of each wall. */
    private id: string;

    /** Front is the plane from start to end. */
    public frontEdge: HalfEdge = null;

    /** Back is the plane from end to start. */
    public backEdge: HalfEdge = null;

    public label: LABEL;
    public labelFocus: any;
    public mesh: THREE.Mesh;


    
    public material: any;
    /** */
    public orphan = false;

    /** Items attached to this wall */
    public items: Item[] = [];

    /** */
    public onItems: Item[] = [];
    /** The front-side texture. */
    public frontTexture = defaultWallTexture;

    /** The back-side texture. */
    public backTexture = defaultWallTexture;

    /** Wall thickness. */
    public thickness = Configuration.getNumericValue(configWallThickness);

    /** Wall height. */
    public height = Configuration.getNumericValue(configWallHeight);
    public preChangeHeight: number;
    /** Actions to be applied after movement. */
    private moved_callbacks = $.Callbacks();

    /** Actions to be applied on removal. */
    private deleted_callbacks = $.Callbacks();

    /** Actions to be applied explicitly. */
    private action_callbacks = $.Callbacks();

    public updateWorldMatrix;
    public getWorldPosition;

    public selected: boolean = false;
    public hover: boolean = false;
    public materials: THREE.Material[] // collected materials of this wall's threejs objects
    public tallestItemPoint: number;
    public startDragPoint: THREE.Vector3;
    /** 
     * Constructs a new wall.
     * @param start Start corner.
     * @param end End corner.
     */
    constructor(private start: Corner, private end: Corner, tHeight?: number) {
      if(tHeight)
        this.height = tHeight;
      this.id = this.getUuid();

      this.start.attachStart(this)
      this.end.attachEnd(this);
      this.tallestItemPoint = 0;
    }
    updateWallHeight(val) {
        this.updateWorldMatrix = null;
        this.getWorldPosition = null;
        this.height = val;
        if(this.height < this.tallestItemPoint) {
          this.height = this.tallestItemPoint;
        }
        if(this.label && this.label.inputElement) {
          this.label.inputElement.value = this.updateLabelValue();
        }
        this.fireRedraw();
        /*
            console.log(edge.geometry.getAttribute('position'));
      console.log(edge.wall.geometry.getAttribute('position'));
      console.log(edge.front.geometry.getAttribute('position'));
  */
        
      

    }
    updateLabelValue() : string {
      let val: string;
      val = "" + window.three.correctUnits(this.height).toFixed(2) + ' ' + window.three.unitSuffix();
      this.label.inputElement.value = val;
      return val;

    }
    customIntersectionPlanes() {
      return null;
    }

    updateHighlight() {
      if(this.hover || this.selected) {
        this.material.color.r = 2.0;
        this.material.color.b = 2.0;
        this.material.needsUpdate = true;
        window.three.highlit = this;
      }
      else {
        if(window.three.highlit === this) {
          window.three.highlit = null;
        }
        this.material.color.r = 1.0;
        this.material.color.b = 1.0;
        this.material.needsUpdate = true;
      }
    }

    mouseOver() {
      if(!this.label) {
        // The label's target is the three.object3D or derivitive class that actually determines the label's position.
        this.labelFocus = this.mesh;
        this.label = new LABEL(this, 'w', this.labelFocus);
        this.label.elem.dataset.objId = this.id;
        this.label.inputElement.addEventListener('change', function(e) {
            let targ = e.target as any;
            this.updateWallHeight(targ.value);
          }.bind(this));
      }
      this.label.show();
      this.hover = true;
      this.updateHighlight();
    }

    mouseOff() {
      this.label.hide();
      this.hover = false;
      this.updateHighlight();
    }
    setSelected() {
      this.selected = true;
      window.three.setSelected(this);
      this.label ? this.label.show() : null;
      return this;
    }
    setUnselected() {
      this.selected = false;
      window.three.setSelected(null);
      if(this.label) {
        this.label.hide();
      }
      this.updateHighlight();
    }
    startDrag(inter) {
      window.three.controller.changeValidTargets('m', 1);  // value of 1 is 'dragging'
      window.three.controller.setDrawing(true);
      if(!this.wallHeightPlane) {
        let geom;
        if(this.backEdge) {
          geom = this.backEdge.plane.clone();
        }
        else {
          geom = this.frontEdge.plane.clone();
        }
        geom.geometry.scale(1, 1000, 1);
        this.wallHeightPlane = geom;
      }
      this.preChangeHeight = parseFloat(this.height as any);

//        var intersection = window.three.controller.getIntersections(currentMouse, [this.wallHeightPlane]);
//        window.three.model.floorplan.wallEdgePlanes());
      if(inter.length) {
        window.three.controls.enabled = false;
        this.startDragPoint = inter[0].point;
        console.log('start drag ' + this.startDragPoint.x + ' ' + this.startDragPoint.y)
      }
    }
    drag() {
      let intersected = window.three.controller.getIntersections(
        window.three.controller.currentMouse, [this.wallHeightPlane], true);
      let heightChange;
      if(intersected.length) {
        let currentPoint = intersected[0].point;
        heightChange = this.preChangeHeight + currentPoint.y - this.startDragPoint.y;
        console.log('clickdragged ' + this.preChangeHeight + ' ' +  this.startDragPoint.y )
        this.updateWallHeight(heightChange);
        console.log(this.labelFocus.position.x)
      }
    };
    stopDrag() {
      window.three.controller.setDrawing(false);
      window.three.controller.changeValidTargets('m');  // value of 1 is 'dragging'
      let intersected = window.three.controller.getIntersections(
        window.three.controller.currentMouse, [this.wallHeightPlane], true);
      let heightChange;
      if(intersected.length) {
        let currentPoint = intersected[0].point;
        heightChange = this.preChangeHeight + currentPoint.y - this.startDragPoint.y;
        console.log('stopdrag ' + this.preChangeHeight + this.startDragPoint.y )
        this.updateWallHeight(heightChange)
      }
      this.setUnselected();
      window.three.controls.enabled = true;
    }


    private getUuid(): string {
      return [this.start.id, this.end.id].join();
    }

    public resetFrontBack() {
      this.frontEdge = null;
      this.backEdge = null;
      this.orphan = false;
    }

    private snapToAxis(tolerance: number) {
      // order here is important, but unfortunately arbitrary
      this.start.snapToAxis(tolerance);
      this.end.snapToAxis(tolerance);
    }

    public fireOnMove(func) {
      this.moved_callbacks.add(func);
    }

    public fireOnDelete(func) {
      this.deleted_callbacks.add(func);
    }

    public dontFireOnDelete(func) {
      this.deleted_callbacks.remove(func);
    }

    public fireOnAction(func) {
      this.action_callbacks.add(func)
    }

    public fireAction(action) {
      this.action_callbacks.fire(action)
    }

    private relativeMove(dx: number, dy: number) {
      this.start.relativeMove(dx, dy);
      this.end.relativeMove(dx, dy);
    }

    public fireMoved() {
      this.moved_callbacks.fire();
    }

    public fireRedraw() {
      if (this.frontEdge) {
        this.frontEdge.redrawCallbacks.fire();
      }
      if (this.backEdge) {
        this.backEdge.redrawCallbacks.fire();
      }
    }

    public getStart(): Corner {
      return this.start;
    }

    public getEnd(): Corner {
      return this.end;
    }

    public getStartX(): number {
      return this.start.getX();
    }

    public getEndX(): number {
      return this.end.getX();
    }

    public getStartY(): number {
      return this.start.getY();
    }

    public getEndY(): number {
      return this.end.getY();
    }

    public remove() {
      this.start.detachWall(this);
      this.end.detachWall(this);
      this.deleted_callbacks.fire(this);
    }

    public setStart(corner: Corner) {
      this.start.detachWall(this);
      corner.attachStart(this);
      this.start = corner;
      this.fireMoved();
    }

    public setEnd(corner: Corner) {
      this.end.detachWall(this);
      corner.attachEnd(this);
      this.end = corner;
      this.fireMoved();
    }

    public distanceFrom(x: number, y: number): number {
      return Utils.pointDistanceFromLine(x, y,
        this.getStartX(), this.getStartY(),
        this.getEndX(), this.getEndY());
    }

    /** Return the corner opposite of the one provided.
     * @param corner The given corner.
     * @returns The opposite corner.
     */
    private oppositeCorner(corner: Corner): Corner {
      if (this.start === corner) {
        return this.end;
      } else if (this.end === corner) {
        return this.start;
      } else {
        console.log('Wall does not connect to corner');
      }
    }
  }

